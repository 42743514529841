<template>
    <div>
        <search-field v-model="inputVal.article"
                      name="search"
                      :label-prefix="labelPrefix"
                      :url="articleSearchUrl"
                      @input-object="articleSelected"
        ></search-field>

        <b-row v-if="inputVal.article">
            <b-col cols="12">
                <b-row v-for="(field,field_index) in inputVal.fields" :key="field_index">
                    <b-col cols="4">
                        <zw-select-group v-model="inputVal.fields[field_index].column"
                                         :options="getModelColumns() | optionsVV"
                                         name="column"
                                         disable-label
                                         :label-prefix="labelPrefix"
                                         validate="required"
                        ></zw-select-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-select-group v-model="inputVal.fields[field_index].type"
                                         :options="['fixed','dynamic'] | optionsVV"
                                         name="type"
                                         disable-label
                                         :label-prefix="labelPrefix"
                                         validate="required"
                        ></zw-select-group>
                    </b-col>
                    <b-col cols="4" v-if="inputVal.fields[field_index].type">
                        <template v-if="inputVal.fields[field_index].type=='fixed'">
                            <zw-input-group v-model="inputVal.fields[field_index].value"
                                            name="value"
                                            disable-label
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </template>
                        <template v-else>
                            <zw-select-group v-model="inputVal.fields[field_index].value"
                                             :options="getAllModelColumns() | optionsVV"
                                             name="column"
                                             disable-label
                                             :label-prefix="labelPrefix"
                                             validate="required"
                            ></zw-select-group>
                        </template>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12">
                <b-button variant="success"
                          @click="addField()"
                          :title="$t('common.title.add')"
                          class="mr-2"
                >
                    <font-awesome-icon icon="plus"/>
                    {{ $t('settings.workflow.button.add_field') }}
                </b-button>

            </b-col>
        </b-row>
    </div>

</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: 'GenerateInvoiceOptions',
    props: {
        'value': [String, Number, Object],
    },
    data() {
        return {
            loading: true,
            search: null,
            articleSearchUrl: window.apiUrl + '/articles/search',
            labelPrefix: 'settings.workflow.label.',
        }
    },
    mounted() {
        const columnsFetch = this.$store.dispatch('CommonData/fetchModelColumns', {name: 'position'})
        const allColumnsFetch = this.$store.dispatch('CommonData/fetchAllModelColumns', {name: 'offering'})

        Promise.all([columnsFetch, allColumnsFetch])
    },
    methods: {
        ...mapGetters('CommonData', ['getModelColumns', 'getAllModelColumns']),
        articleSelected(article, with_category = false, return_focus = false, refs = '') {

        },
        addField() {
            let fields = this.inputVal.fields || []
            fields.push({})
            this.$set(this.inputVal, 'fields', fields)
        }
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    },
}
</script>